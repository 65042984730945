import $ from '../../../../../src/Resources/core/jsx/utils/jquery-global';
import Widgets from 'js-widget-hooks';
import 'bootstrap/js/alert';

Widgets.register('ajax-send', function(elem){
    var form = $(elem);

    /**
     *
     * @param {string} message
     * @param {string} [severity=success]
     */
    var showMessage = function (message, severity) {
        form.find('.alert').remove();

        form.find('.modal-body').append(`<div class="alert alert-${severity || 'success'} alert-dismissible" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            ${message}
        </div>`)
    };

    form.on('submit', function (e) {
        e.preventDefault();
        form.find('.alert').remove();

        if (!elem.reportValidity || elem.reportValidity()) {

            form.addClass('loading');
            var values = form.serializeArray();
            values.push({
                name: 'url',
                value: window.location.href
            });

            $.post(form.attr('action'), values, function (response) {
                if (response && response.success) {
                    showMessage('Ihr Feedback wurde versendet, vielen Dank.');
                    elem.reset();
                } else {
                    showMessage('Ein fehler ist aufgetreten: ' + response.error, 'danger');
                }
            }).fail(function (error) {
                showMessage('Ein fehler ist aufgetreten: ' + error, 'danger');
            }).always( function () {
                form.removeClass('loading');
            });
        }
    })
});