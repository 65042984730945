import $ from '../../../../../src/Resources/core/jsx/utils/jquery-global';
import Widgets from 'js-widget-hooks';

Widgets.register('search', function(elem){

    var el = $(elem);
    var button = el.find('.search-button');
    var input = el.find('.search-input');
    var navbarMode = el.parent().hasClass('navbar-right');
    var navBar = navbarMode ? el.closest('#main-navigation') : null;
    var formClosedWidth = navbarMode ? navBar.find('.navbar-right').outerWidth() : 0;

    el.on('mouseleave', function () {

        if (!input.val()) {
            el.removeClass('active');
        }
    });

    button.on("click mouseenter", function () {

        // let's calculate the available space, if it is the navbar
        if (navbarMode) {
            var navBar = el.closest('#main-navigation');
            var maxWidth = navBar.outerWidth() - 30 - navBar.find('.navbar-nav').outerWidth() - formClosedWidth
            input.css({maxWidth: maxWidth});
        }

        el.addClass('active');
        input[0].focus();
    });

    el.on('submit', function (e) {
        if (!input.val()) {
            e.preventDefault();
        } else {
            el.addClass('active');
            input[0].focus();
        }
    })


});