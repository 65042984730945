import $ from '../../../../../src/Resources/core/jsx/utils/jquery-global';
import Widgets from 'js-widget-hooks';
import 'bootstrap/js/tab';

Widgets.register('tab', function(elem){
    // aparently it just works with the tab.js ...
    $(elem).find('[data-toggle="tab"]').on('hide.bs.tab', function (e) {
        const target = $(e.target.getAttribute('href'));
        target.find('video, audio').each(function () {
            this.stop();
        });
        target.find('iframe').each(function () {
            console.log('stoping %o', this);
            $(this).attr('src', this.getAttribute('src'));
        });
    })
});


