import Widgets from 'js-widget-hooks';
import '../../../../src/Resources/core/jsx/components/widgets';
import './widgets/glossary';
import './widgets/topic';
import './widgets/search'; // should this be part of the core?
import './widgets/imagezoom';
import './widgets/ajax-send';
import '../../../../src/Resources/core/jsx/utils/matomo';

// initialize all the widgets
Widgets.init();
