import $ from '../../../../../src/Resources/core/jsx/utils/jquery-global';
import Widgets from 'js-widget-hooks';
import 'bootstrap/js/transition';
import 'bootstrap/js/collapse';

const hashPrefix = '!def:';

Widgets.register('glossary', function(elem){

    const el = $(elem);
    const hashId = document.location.hash.substr(hashPrefix.length + 1); // +1 because of the # sign, that is also returned

    // a bit of custom code, to allow the closing in multiple collumns
    el.find('.collapse').on('show.bs.collapse', function (e) {
        document.location.hash = hashPrefix + $(this).data('hash');
        el.find('.collapse.in').collapse('hide');
    });

    if (hashId) {
        el.find('.collapse[data-hash=' + hashId + ']').collapse('show')
    }
});