import $ from '../../../../../src/Resources/core/jsx/utils/jquery-global';
import 'simplelightbox';
import Widgets from 'js-widget-hooks';

Widgets.register('imagezoom', function (elem) {
    var el = $(elem);
    el.find('img').attr('title', 'Doppelt klicken oder mit zwei Fingern außeinanderziehen um zu zoomen.');
    el.find('a').simpleLightbox({
        'captionPosition': 'bottom'
    });
});